.btn {
    background-color: $color-white;
    border: 2px solid $color-black;
    border-radius: 44px;
    width: 188px;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: $font-size-20;
    color: $color-black;
    text-transform: uppercase;
    transition: all 0.2s ease-in;

    &:hover {
        transition: all 0.2s ease-in;
        background-color: $color-1;
        color: $color-white;
        border: 2px solid $color-1;
    }
}
