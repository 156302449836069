footer {
    .section-footer {
        width: 100%;
        padding: 15px 5% 75px 5%;
        background: #0000000B;
        border-top: 1px solid $color-1;
        a.section-arrow {
            justify-content: right;
        }
        .footer-info {
            padding-top: 50px;
            h3 {
                font-weight: normal;
                font-size: $font-size-30;
                color: $color-black;
                text-transform: initial;
                padding-bottom: 50px;
            }
            h4, a {
                font-weight: normal;
                font-size: $font-size-20;
                color: $color-black;
            }
            .logo {
                margin-top: -50px;
                width: 26%;
            }
            .section-heures {
                width: 35%;
            }
            .section-adresse {
                width: 22%;
            }
            .section-heures {
                .bloc {
                    .border {
                        background: #707070;
                        height: 2px;
                        margin: 0 15px;
                        &.first {
                            width: 232px; 
                        }
                        &.second {
                            width: 100px; 
                        }
                        &.third {
                            width: 215px; 
                        }
                        &.four {
                            width: 187px; 
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1545px) {
            .footer-info {
                justify-content: space-between;
                .logo, .section-heures,.section-adresse {
                    width: initial;
                }
            }
            @media screen and (max-width: 1360px) {
                .footer-info {
                    .section-heures {
                        .bloc {
                            .border.first {
                                width: 185px;
                            }
                            .border.second {
                                width: 55px;
                            }
                            .border.third {
                                width: 169px;
                            }
                            .border.four {
                                width: 145px;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1200px) {
                    padding: 15px 5% 50px 5%;
                    .footer-info {
                        flex-direction: column;
                        h3 {
                            padding-bottom: 25px;
                        }
                        .section-heures {
                            margin: 50px 0 15px 0;
                            .bloc .border.second {
                                width: 65px;
                            }
                        }
                        .section-adresse {
                            margin: 0px 0 35px 0;
                        }
                    }
                    @media screen and (max-width: $size-sm-max) {
                        .footer-info {
                            .section-heures {
                                .bloc {
                                    .border.second {
                                        width: 79px;
                                    }
                                    .border.third {
                                        width: 170px;
                                    }
                                    .border.four {
                                        width: 149px;
                                    }
                                }
                            }
                        }
                        @media screen and (max-width: $size-xs-max) {
                            .footer-info {
                                .logo {
                                    margin-top: -15px;
                                }
                                .section-heures {
                                    .bloc {
                                        .border.second {
                                            width: 93px;
                                        }
                                        .border.third {
                                            width: 173px;
                                        }
                                        .border.four {
                                            width: 155px;
                                        }
                                    }
                                }
                            }
                            @media screen and (max-width: 375px) {
                                .footer-info {
                                    .section-heures {
                                        .bloc {
                                            .border.first {
                                                width: 151px;
                                            }
                                            .border.second {
                                                width: 60px;
                                            }
                                            .border.third {
                                                width: 140px;
                                            }
                                            .border.four {
                                                width: 121px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-wrapper {
        width: 100%;
        background: $color-black;
        height: 105px;
        padding: 0 5%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        .copyright {
            font-weight: normal;
            font-size: 15px;
            color: $color-1;
            width: 26%;
        }
        .paiement {
            margin-right: 7%;
        }
        .facebook {
            position: absolute;
            right: 5%;
        }
        @media screen and (max-width: 1545px) {
            .copyright {
                width: 30%;
            }
            @media screen and (max-width: 1360px) {
                .copyright {
                    width: 35%;
                }
                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                    padding: 35px 5%;
                    height: 225px;
                    .copyright {
                        width: 100%;
                        text-align: center;
                    }
                    .paiement {
                        margin: 35px 0;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        height: 265px;
                        .facebook {
                            position: initial;
                            margin-top: 25px;
                            display: flex;
                            justify-content: right;
                            width: 100%;
                        }
                        @media screen and (max-width: 375px) {
                            height: 277px;
                        }
                    }
                }
            }
        }
    }
}
