// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

a.btn-link {
    border: 2px solid #000;
    border-radius: 44px;
    width: 264px;
    height: 67px;
    margin: 50px auto 0 auto;
    transition: 0.3s;
    h5 {
        font-weight: 600;
        font-size: $font-size-20;
        color: $color-black;
        transition: 0.3s;
    }
    &:hover {
        background: $color-1;
        border: 2px solid $color-1;
        transition: 0.3s;
        h5 {
            color: $color-white;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        width: 215px;
    }
}

.visibilityCheck {
    opacity: 0;
    transition: 2s;
    &.isVisible {
        opacity: 1;
        transition: 2s;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 3px;
            background:rgba(0, 0, 0, .35);//hsla(9, 100%, 64%, 0.4);//
        }
        &_caption {
            position: absolute;
            top: 42%;
            left: 5%;
            right: 5%;
            .contenu {
                .menu-toggle-home {
                    cursor: pointer;
                    .bar-top, .bar-center, .bar-bottom {
                        width: 122px;
                        height: 15px;
                        background: $color-white;
                    }
                    .bar-center {
                        margin: 15px auto;
                        transition: 0.5s;
                    }
                    &:hover {
                        .bar-center {
                            width: 50px;
                            transition: 0.5s;
                        }
                    }
                }
                .text-side {
                    h2 {
                        font-weight: bold;
                        font-size: $font-size-50;
                        color: $color-white;
                        text-transform: uppercase;
                        padding-bottom: 5px;
                    }
                    h3 {
                        font-weight: bold;
                        font-size: $font-size-30;
                        color: $color-white;
                        text-transform: uppercase;
                        padding-bottom: 25px;
                    }
                    p {
                        color: $color-white;
                        text-align: initial;
                    }
                    @media screen and (max-width: 1400px){
                        h2 {
                            font-size: 2.25rem;
                        }
                        h3 {
                            font-size: 1.25rem;
                        }
                        @media screen and (max-width: 1380px){
                            &.mx8 {
                                margin-left: 5rem;
                                margin-right: 5rem;
                            }
                            @media screen and (max-width: 1200px){
                                display: none;
                            }
                        }
                    }
                }
                .logo-tel {
                    text-align: center;
                    .img-responsive {
                        margin-bottom: 20px;
                    }
                    a {
                        font-weight: bold;
                        font-size: $font-size-22;
                        color: $color-white;
                    }
                }
            }

            #menu-mobile {
                .mobile {
                    position: fixed;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background: url("../images/menu.jpg") no-repeat;
                    background-position: center;
                    transform: translate(100%, 0);
                    transition: 1s;
                    &.toggled {
                        transform: translate(0);
                        transition: 1s;
                        padding: 35px 5%;
                        z-index: 1;
                    }
                    .menu-toggle-home {
                        width: 100%;
                        position: relative;
                        .btn-back {
                            cursor: pointer;
                            margin-top: 50px;
                            .text {
                                width: 100%;
                                h5 {
                                    font-weight: bold;
                                    font-size: $font-size-30;
                                    color: $color-white;
                                }
                            }
                        }
                        .logo-tel {
                            text-align: center;
                            position: absolute;
                            left: 50%;
                            top: 0;
                            transform: translate(-50%, 0);
                            .img-responsive {
                                margin-bottom: 15px;
                            }
                            a {
                                font-weight: bold;
                                font-size: $font-size-20;
                                color: $color-white;
                            }
                            @media screen and (max-width: $size-xs-max) {
                                width: 40%;
                            }
                        }
                        @media screen and (max-width: 1024px) {
                            justify-content: space-between;
                            .btn-back {
                                margin-top: 0;
                            }
                            .logo-tel {
                                position: initial;
                                transform: initial;
                            }
                        }
                    }
                    ul.menu {
                        list-style: none;
                        li.item {
                            line-height: 1.5;
                            a {
                                font-weight: bold;
                                font-size: $font-size-60;
                                color: $color-white;
                                text-transform: initial;
                            }
                            .border {
                                background: #fff;
                                width: 90px;
                                height: 2px;
                                opacity: 0;
                                transition: 0.3s;
                            }
                            &:hover .border {
                                opacity: 1;
                                transition: 0.3s;
                            }
                        }
                        @media screen and (max-width: 1600px){
                            li.item a {
                                font-size: 2.7rem;
                            }
                            @media screen and (max-width: 1350px){
                                li.item a {
                                    font-size: 2.2rem;
                                }
                                @media screen and (max-width: 1024px){
                                    li.item a {
                                        font-size: 2rem;
                                    }
                                    @media screen and (max-width: $size-sm-max) {
                                        flex-direction: column;
                                        li.item {
                                            line-height: 2;
                                            a {
                                                font-size: 2.2rem;
                                            }
                                        }
                                        @media screen and (max-width: 375px) {
                                            li.item a {
                                                font-size: 2rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .facebook {
                        h5 {
                            font-weight: bold;
                            font-size: $font-size-20;
                            color: $color-white;
                            padding-bottom: 35px;
                        }
                    }
                }
            }
            
            @media screen and (max-width: 1200px){
                top: 4%;
                .contenu {
                    .menu-toggle-home {
                        .bar-top, .bar-center,.bar-bottom {
                            width: 80px;
                            height: 10px;
                        }
                    }
                    .logo-tel {
                        .img-responsive {
                            width: 85%;
                            margin: 0 auto 20px auto;
                        }
                    }
                }
                @media screen and (max-width: 1024px){
                    top: 4%;
                    .contenu {
                        .menu-toggle-home {
                            .bar-top, .bar-center,.bar-bottom {
                                width: 65px;
                                height: 5px;
                            }
                        }
                        .logo-tel {
                            .img-responsive {
                                width: 70%;
                                margin: 0 auto 15px auto;
                            }
                            a {
                                font-size: 1rem;
                            }
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        right: 0%;
                    }
                }
            }
        }
        .text-mobile {
            position: absolute;
            top: 55%;
            left: 5%;
            right: 5%;
            display: none;
            h2 {
                font-weight: bold;
                font-size: $font-size-50;
                color: $color-white;
                text-transform: uppercase;
                padding-bottom: 5px;
            }
            h3 {
                font-weight: bold;
                font-size: $font-size-30;
                color: $color-white;
                text-transform: uppercase;
                padding-bottom: 25px;
            }
            p {
                color: $color-white;
                text-align: initial;    
            }
            @media screen and (max-width: 1200px){
                display: block;
                @media screen and (max-width: $size-sm-max) {
                    top: 52%;
                    @media screen and (max-width: $size-xs-max) {

                        p br {
                            display: none;
                        }
                    }
                }
            }
        }
        a.scroll-link {
            position: absolute;
            bottom: 50px;
            left: 0;
            right: 0;
            .mouse {
                max-width: 2.5rem;
                width: 100%;
                height: auto;
            }
            .scroll {
                animation-name: scroll;
                animation-duration: 1.5s;
                animation-timing-function: cubic-bezier(0.650, -0.550, 0.250, 1.500);
                animation-iteration-count: infinite;
                transform-origin: 50% 20.5px;
                will-change: transform, opacity;
                opacity: 1;
            }
            .scroll-link {
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
            }
            @keyframes scroll {
                0%, 20% {
                    transform: translateY(0) scaleY(1);
                }
                100% {
                    transform: translateY(36px) scaleY(2);
                    opacity: 0;
                }
            }
            @media screen and (max-width: 1200px){
                display: none;
            }
        }
    }
}

#section-text {
    padding: 75px 5%;
    text-align: center;
    position: relative;
    h3 {
        font-weight: bold;
        font-size: $font-size-60;
        color: $color-black;
        padding-bottom: 55px;
    }

    .title {
        position: relative;
        h4 {
            font-weight: normal;
            font-size: $font-size-30;
            color: $color-black;
            padding-bottom: 10px;
        }
        .icon {
            position: absolute;
            top: -21px;
            left: 30%;
            @media screen and (max-width: 1790px) {
                left: 28%;
                @media screen and (max-width: 1615px) {
                    left: 25%;
                    @media screen and (max-width: 1500px) {
                        left: 22%;
                        @media screen and (max-width: 1155px) {
                            left: 18%;
                            @media screen and (max-width: $size-sm-max) {
                                left: 9%;
                                @media screen and (max-width: $size-xs-max) {
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    h5.name {
        font-family: $font-family-2;
        font-weight: 600;
        font-size: $font-size-16;
        color: $color-black;
        letter-spacing: 4px;
        text-transform: uppercase;
    }

    .text-box {
        padding-top: 100px;
        h4 {
            font-weight: bold;
            font-size: $font-size-20;
            color: $color-black;
            padding-bottom: 5px;
        }
    }

    .facebook {
        padding-top: 50px;
        h5 {
            font-weight: bold;
            font-size: $font-size-20;
            color: $color-black;
        }
        .img-responsive {
            margin: 35px auto;
        }
    }
    .logo {
        position: absolute;
        top: -42%;
        @media screen and (max-width: 1024px) {
            position: initial;
            @media screen and (max-width: $size-xs-max) {
                .img-responsive {
                    margin: auto;
                }
            }
        }
    }
    @media screen and (max-width: 1082px) {
        p br {
            display: none;
        }
        &.third {
            padding: 0 5% 50px 5%;
        }
        @media screen and (max-width: $size-sm-max) {
            padding: 50px 5%;
            @media screen and (max-width: $size-xs-max) {
                &.first {
                    padding: 85px 5% 50px 5%;
                }
            }
        }
    }
}

#section-images {
    padding: 25px 0;
    @media screen and (max-width: $size-xs-max) {
        flex-direction: column;
        .image.mr3 {
            margin: 0 0 0.75rem 0;
        }
    }
}

#section-background {
    padding: 35px 0;
    position: relative;
    text-align: center;
    .text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            font-weight: bold;
            font-size: $font-size-50;
            color: $color-white;
            text-transform: uppercase;
            padding-bottom: 15px;
        }
        h5 {
            font-family: $font-family-2;
            font-weight: 600;
            font-size: $font-size-16;
            color: $color-white;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }
}

#section-image {
    padding: 35px 0 50px 0;
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-text.services {
    padding: 75px 5% 120px 5%;
    h3.first {
        padding-bottom: 15px;
    }
    h4 {
        font-family: $font-family-2;
        font-weight: 600;
        font-size: $font-size-16;
        color: $color-1;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
}

#section-background.service {
    padding: 0;
}

/*******************************************************************************
* GALERIE
*******************************************************************************/

#section-realisations {
    padding: 50px 5%;
    .realisations {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        .bloc {
            .img-responsive {
                width: 100%;
            }
        }
        @media screen and (max-width: $size-xs-max){
            grid-template-columns: repeat(1, 1fr);     
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    position: relative;
    .section-info {
        position: absolute;
        bottom: 12%;
        left: 0;
        right: 0;
        padding: 0 5%;
        .bloc {
            h2 {
                font-weight: normal;
                font-size: $font-size-50;
                color: $color-white;
                padding-bottom: 44px;
            }
            h4, a {
                font-weight: normal;
                font-size: $font-size-20;
                color: $color-white;
            }
            .jours-heures {
                h4 {
                    line-height: 2.5;
                }
                .jour {
                    h4 {
                        .border {
                            width: 150px;
                            height: 1px;
                            margin: 0 15px;
                            background: $color-white;
                        }
                        &:first-child .border {
                            width: 281px;
                        }
                        &:nth-child(3) .border {
                            width: 263px;
                        }
                        &:last-child .border {
                            width: 235px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1650px) {
        .section-info {
            .bloc {
                h2 {
                    font-size: 2.25rem;
                }
            }
        }
        @media screen and (max-width: 1550px) {
            .section-info {
                .bloc {
                    h2 {
                        font-size: 2rem;
                    }
                }
            }
            @media screen and (max-width: 1510px) {
                .section-info .bloc h4, .section-info .bloc a {
                    font-size: 0.9rem;
                }
                @media screen and (max-width: 1420px) {
                    .section-info {
                        position: initial;
                        padding: 75px 5%;
                        flex-wrap: wrap;
                        .bloc {
                            width: 50%;
                            margin: 0 0 50px 0;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            h2, h4, a {
                                color: $color-black;
                            }
                            .jours-heures {
                                .jour {
                                    h4 {
                                        .border {
                                            width: 162px;
                                            background: $color-black;
                                        }
                                        &:first-child .border {
                                            width: 280px;
                                        }
                                        &:nth-child(3) .border {
                                            width: 264px;
                                        }
                                        &:last-child .border {
                                            width: 240px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    @media screen and (max-width: $size-md-max) {
                        .section-info {
                            .bloc {
                                .jours-heures {
                                    .jour {
                                        h4 {
                                            .border {
                                                width: 171px;
                                            }
                                            &:first-child .border {
                                                width: 278px;
                                            }
                                            &:last-child .border {
                                                width: 242px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        @media screen and (max-width: 1040px) {
                            .section-info {
                                flex-direction: column;
                                padding: 75px 5% 0 5%;
                                .bloc {
                                    width: 100%;
                                    margin: 0 0 35px 0;
                                    align-items: initial;
                                    h2 {
                                        font-size: 1.6rem;
                                    }
                                }
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .section-info {
                                    .bloc {
                                        .jours-heures {
                                            .jour {
                                                h4 {
                                                    .border {
                                                        width: 85px;
                                                    }
                                                    &:first-child .border {
                                                        width: 168px;
                                                    }
                                                    &:nth-child(3) .border {
                                                        width: 156px;
                                                    }
                                                    &:last-child .border {
                                                        width: 140px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-form-map {
    padding: 75px 5% 25px 5%;
    .title {
        padding-bottom: 132px;
        h3 {
            font-weight: bold;
            font-size: $font-size-50;
            color: $color-black;
            padding-bottom: 15px;
        }
        h4 {
            font-family: $font-family-2;
            font-weight: 600;
            font-size: $font-size-16;
            color: $color-1;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }
    .section-form {
        width: 50%;
        margin-right: 5%;
    }
    .blocMap {
        width: 50%;
    }
    @media screen and (max-width: 1250px) {
        .form-map {
            flex-direction: column;
            .section-form {
                width: 100%;
                margin: 0 0 75px 0;
            }
            .blocMap {
                width: 100%;
            }
        }
        @media screen and (max-width: $size-xs-max) {
            .form-map {
                .section-form {
                    .col-xs-12.col-sm-6.pl0, .col-xs-12.col-sm-6.pr0 {
                        padding: 0;
                    }
                }
            }
            .title {
                padding-bottom: 50px;
                text-align: center;
            }
        }
    }
}

#section-images.coordonnees {
    padding: 75px 5%;
    @media screen and (max-width: $size-xs-max) {
        .left-side {
            margin: 0 0 0.75rem 0;
        }
    }
}

#content {
    h3 {
        padding-bottom: 5px;
        color: $color-1;
    }
    p {
        text-align: initial;
        a {
            color: $color-black;
        }   
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   

}
